import { handleActions } from 'redux-actions'

import produce from 'immer'

import {
  fetchStarted,
  clearDataSuccessed,
  clearDataFailed,
  fetchSuccessed,
  fetchFailed,
  fetchFinished,
} from './actions'

const initialState = {
  loading: false,
  data: [],
  error: false,
}

const reducer = handleActions({
  [fetchStarted]: state => produce(state, next => {
    next.loading = true
  }),
  [fetchSuccessed]: (state, action) => produce(state, next => {
    next.data = action.payload
  }),
  [clearDataSuccessed]: (state, action) => produce(state, next => {
    next.data = action.payload
  }),
  [clearDataFailed]: (state, action) => produce(state, next => {
    next.error = action.payload
  }),
  [fetchFailed]: (state, action) => produce(state, next => {
    next.error = action.payload
  }),
  [fetchFinished]: state => produce(state, next => {
    next.loading = false
  }),
}, initialState)

export default reducer
