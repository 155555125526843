import {
  takeLatest, put, call,
} from 'redux-saga/effects'

import { PresentationService } from 'services'

import {
  slideChange,
  resetProgress,
  slideChangeStarted,
  slideChangeSuccessed,
  slideChangeFailed,
  slideChangeFinished,
  resetProgressStarted,
  resetProgressSuccessed,
  resetProgressFailed,
  resetProgressFinished,
} from './actions'

function* slideChangeTask(action) {
  yield put(slideChangeStarted())

  try {
    const success = yield call([PresentationService, 'slideChange'], action.payload)
    yield put(slideChangeSuccessed(success))
  } catch (err) {
    yield put(slideChangeFailed(err))
  }

  yield put(slideChangeFinished())
}

function* resetProgressTask(action) {
  yield put(resetProgressStarted())

  try {
    const success = yield call([PresentationService, 'resetProgress'], action.payload)
    yield put(resetProgressSuccessed(success))
  } catch (err) {
    yield put(resetProgressFailed(err))
  }

  yield put(resetProgressFinished())
}

export default function* () {
  yield takeLatest(slideChange, slideChangeTask)
  yield takeLatest(resetProgress, resetProgressTask)
}
