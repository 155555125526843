import React from 'react'
import { Input } from 'components'

const Profile = () => (
  <div>
    <Input placeholder="test" />
  </div>
)

export default Profile
