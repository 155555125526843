import { createAction } from 'redux-actions'

import { ReduxUtils } from 'utils'

const slideChangeStarted = createAction('PRESENTATION/SLIDE_CHANGE_STARTED')
const slideChangeSuccessed = createAction('PRESENTATION/SLIDE_CHANGE_SUCCESSED')
const slideChangeFailed = createAction('PRESENTATION/SLIDE_CHANGE_FAILED')
const slideChangeFinished = createAction('PRESENTATION/SLIDE_CHANGE_FINISHED')

const resetProgressStarted = createAction('PRESENTATION/RESET_PROGRESS_STARTED')
const resetProgressSuccessed = createAction('PRESENTATION/RESET_PROGRESS_SUCCESSED')
const resetProgressFailed = createAction('PRESENTATION/RESET_PROGRESS_FAILED')
const resetProgressFinished = createAction('PRESENTATION/RESET_PROGRESS_FINISHED')


const slideChange = ReduxUtils.waitForAction(
  createAction('PRESENTATION/SLIDE_CHANGE', (slide, lessonId, completed) => ({
    slide,
    lessonId,
    completed,
  })),
  slideChangeSuccessed,
  slideChangeFailed,
)

const resetProgress = ReduxUtils.waitForAction(
  createAction('PRESENTATION/RESET_PROGRESS', lessonId => ({
    lessonId,
  })),
  resetProgressSuccessed,
  resetProgressFailed,
)

export {
  slideChange,
  resetProgress,
  resetProgressStarted,
  resetProgressSuccessed,
  resetProgressFailed,
  resetProgressFinished,
  slideChangeStarted,
  slideChangeSuccessed,
  slideChangeFailed,
  slideChangeFinished,
}
