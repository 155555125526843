import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import {
  Auth,
  Workspace,
} from 'containers'

import { Router, Loading } from 'components'

import { Selectors } from 'ducks'

import { FunctionUtils } from 'utils'


const Root = ({ isInitialized }) => {
  if (!isInitialized) {
    return <Loading />
  }

  return (
    <Router.Switch>
      <Router.Route exact path="/sign-in" component={ Auth.SignIn } />
      <Router.Route exact path="/sign-out" component={ Auth.SignOut } />

      <Router.ProtectedRoute path="/" component={ Workspace } />
    </Router.Switch>
  )
}

Root.propTypes = {
  isInitialized: PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({
  isInitialized: Selectors.App.isInitialized(state),
})

export default FunctionUtils.compose(
  Router.withRouter,
  connect(mapStateToProps),
)(Root)
