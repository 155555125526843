import {
  takeLatest, put, call,
} from 'redux-saga/effects'

import { SubjectsService } from 'services'

import {
  fetch,

  fetchStarted,
  fetchSuccessed,
  fetchFailed,
  fetchFinished,
} from './actions'

function* fetchTask(action) {
  yield put(fetchStarted())

  try {
    const subjects = yield call([SubjectsService, 'getSubjects'], action.payload)

    yield put(fetchSuccessed(subjects))
  } catch (err) {
    yield put(fetchFailed(err))
  }

  yield put(fetchFinished())
}

export default function* () {
  yield takeLatest(fetch, fetchTask)
}
