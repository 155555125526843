import {
  takeLatest, put, call,
} from 'redux-saga/effects'

import { LessonsService } from 'services'

import {
  fetch,
  clearData,
  clearDataFailed,
  clearDataSuccessed,
  fetchStarted,
  fetchSuccessed,
  fetchFailed,
  fetchFinished,
} from './actions'

function* fetchTask(action) {
  yield put(fetchStarted())

  try {
    const lessons = yield call([LessonsService, 'getLessons'], action.payload)
    yield put(fetchSuccessed(lessons))
  } catch (err) {
    yield put(fetchFailed(err))
  }

  yield put(fetchFinished())
}

function* clearDataTask(action) {
  try {
    const lessons = []
    yield put(clearDataSuccessed(lessons))
  } catch (err) {
    yield put(clearDataFailed(err))
  }
}

export default function* () {
  yield takeLatest(fetch, fetchTask)
  yield takeLatest(clearData, clearDataTask)
}
