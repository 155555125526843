

export default class WeeksService {
  constructor(apiService) {
    this._apiService = apiService
  }

  _mapWeek = week => ({
    discipline: week.discipline,
    id: week.id,
    name: week.name,
    resource_url: week.resource_url,
  })

  _mapWeeks = weeks => weeks.map(week => this._mapWeek(week))


  getWeeks(subjectId) {
    return this._apiService.get(`/discipline_weeks/${subjectId}`).then(weeks => this._mapWeeks(weeks.data)).catch(e => {
      throw e
    })
  }
}
