import * as Mapping from './mapping'

export default class SubjectsService {
  constructor(apiService) {
    this._apiService = apiService
  }

  getSubjects(classId) {
    return this._apiService.get(`/disciplines/${classId}`).then(subjects => Mapping.subjects(subjects.data))
  }
}
