export const subject = _subject => ({
  id: _subject.id,
  classId: _subject.class_number_id,
  image: _subject.image,
  week: _subject.last_week,
  numberLesson: _subject.last_lesson,
  title: _subject.name,
})

export const subjects = _subjects => _subjects.map(_subject => subject(_subject))
