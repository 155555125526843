import {
  takeLatest, put, call,
} from 'redux-saga/effects'

import { ClassesService } from 'services'

import {
  getClasses,
  addClass,
  editClass,
  deleteClass,
  fetchStarted,
  fetchGetSuccessed,
  fetchAddSuccessed,
  fetchDelSuccessed,
  fetchEditSuccessed,
  fetchFailed,
  fetchFinished,
} from './actions'

function* fetchGetClasses() {
  yield put(fetchStarted())

  try {
    const classes = yield call([ClassesService, 'getClasses'])

    yield put(fetchGetSuccessed(classes))
  } catch (err) {
    yield put(fetchFailed(err))
  }

  yield put(fetchFinished())
}

function* fetchAddClass(action) {
  const {
    payload: {
      number,
      literal,
    },
  } = action

  yield put(fetchStarted())

  try {
    const _class = yield call([ClassesService, 'addClass'], number, literal)
    yield put(fetchAddSuccessed(_class))
  } catch (err) {
    yield put(fetchFailed(err))
  }
}

function* fetchDelClass(action) {
  const {
    payload: {
      classID,
    },
  } = action

  yield put(fetchStarted())

  try {
    const _class = yield call([ClassesService, 'deleteClass'], classID)
    yield put(fetchDelSuccessed(_class))
  } catch (err) {
    yield put(fetchFailed(err))
  }
}

function* fetchEditClass(action) {
  const {
    payload: {
      classID,
      number,
      literal,
    },
  } = action

  yield put(fetchStarted())

  try {
    const _class = yield call([ClassesService, 'editClass'], classID, number, literal)

    yield put(fetchEditSuccessed(_class))
  } catch (err) {
    yield put(fetchFailed(err))
  }
}

export default function* () {
  yield takeLatest(getClasses, fetchGetClasses)
  yield takeLatest(addClass, fetchAddClass)
  yield takeLatest(editClass, fetchEditClass)
  yield takeLatest(deleteClass, fetchDelClass)
}
