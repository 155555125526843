import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { Input, Button, Grid } from 'components'

import { FunctionUtils } from 'utils'

import { parser as inputParser } from './utils'

import styles from './styles.module.scss'

export default class Pagination extends PureComponent {
  static propTypes = {
    slide: PropTypes.number,
    total: PropTypes.number,
    isLastFragmentCurrentSlide: PropTypes.bool,
    isFirstFragmentCurrentSlide: PropTypes.bool,
    onChange: PropTypes.func,
    onPrev: PropTypes.func,
    onNext: PropTypes.func,
  }

  static defaultProps = {
    slide: 1,
    total: 1,
    isLastFragmentCurrentSlide: true,
    isFirstFragmentCurrentSlide: true,
    onChange: FunctionUtils.stub(),
    onPrev: FunctionUtils.stub(),
    onNext: FunctionUtils.stub(),
  }

  state = {
    slide: this.props.slide,
  }

  handleSubmit = event => {
    event.preventDefault()

    this.props.onChange(this.state.slide)

    event.target.blur()
  }

  handleSlideChange = (value = '') => {
    this.setState({ slide: value })
  }

  handleDisableBtn = (btn, total, slide, isLastFragmentCurrentSlide, isFirstFragmentCurrentSlide) => {
    if (btn === 'next') {
      if (total === slide && isLastFragmentCurrentSlide) {
        return true
      }
      return false
    }
    if (btn === 'prev') {
      if (total === 1 && isFirstFragmentCurrentSlide) {
        return true
      }
      return false
    }
    return false
  }

  render() {
    const {
      onPrev, onNext, total, isLastFragmentCurrentSlide, isFirstFragmentCurrentSlide,
    } = this.props

    const {
      slide,
    } = this.state

    return (
      <Grid.Row
        className={ styles.pagination }
        type="flex"
        align="middle"
        gutter={ 16 }
      >
        <Grid.Col>
          <Button
            className={ styles.btn }
            icon="left"
            disabled={
              this.handleDisableBtn('prev', slide, total, isLastFragmentCurrentSlide, isFirstFragmentCurrentSlide)
             }
            onClick={ onPrev }
          />
        </Grid.Col>
        <Grid.Col>
          <form onSubmit={ this.handleSubmit }>
            <Input.Number
              className={ styles.input }
              parser={ inputParser }
              value={ slide }
              onChange={ this.handleSlideChange }
              onBlur={ this.handleSubmit }
              min={ 1 }
              max={ total }
            />
            <span className={ styles.span }>／</span>
            <span>{ total }</span>
          </form>
        </Grid.Col>
        <Grid.Col>
          <Button
            className={ styles.btn }
            icon="right"
            disabled={
              this.handleDisableBtn('next', slide, total, isLastFragmentCurrentSlide, isFirstFragmentCurrentSlide)
            }
            onClick={ onNext }
          />
        </Grid.Col>
      </Grid.Row>
    )
  }
}
