import { createAction } from 'redux-actions'

import { ReduxUtils } from 'utils'

const fetchStarted = createAction('LESSONS/FETCH_STARTED')
const fetchSuccessed = createAction('LESSONS/FETCH_SUCCESSED')
const fetchFailed = createAction('LESSONS/FETCH_FAILED')
const fetchFinished = createAction('LESSONS/FETCH_FINISHED')
const clearDataSuccessed = createAction('LESSONS/CLEAR_DATA_SUCCESSED')
const clearDataFailed = createAction('LESSONS/CLEAR_DATA_FAILED')

const fetch = ReduxUtils.waitForAction(
  createAction('LESSONS/FETCH'),
  fetchSuccessed,
  fetchFailed,
)

const clearData = ReduxUtils.waitForAction(
  createAction('LESSONS/CLEAR_DATA'),
  clearDataSuccessed,
  clearDataFailed,
)


export {
  fetch,
  clearData,
  fetchStarted,
  fetchSuccessed,
  fetchFailed,
  fetchFinished,
  clearDataSuccessed,
  clearDataFailed,
}
