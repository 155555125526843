const calendarLocale = {
  today: 'Today',
  now: 'Now',
  backToToday: 'Back to today',
  ok: 'Ok',
  clear: 'Clear',
  month: 'Month',
  year: 'Year',
  timeSelect: 'select time',
  dateSelect: 'select date',
  weekSelect: 'Choose a week',
  monthSelect: 'Choose a month',
  yearSelect: 'Choose a year',
  decadeSelect: 'Choose a decade',
  yearFormat: 'YYYY',
  dateFormat: 'M/D/YYYY',
  dayFormat: 'D',
  dateTimeFormat: 'M/D/YYYY HH:mm:ss',
  monthBeforeYear: true,
  previousMonth: 'Previous month (PageUp)',
  nextMonth: 'Next month (PageDown)',
  previousYear: 'Last year (Control + left)',
  nextYear: 'Next year (Control + right)',
  previousDecade: 'Last decade',
  nextDecade: 'Next decade',
  previousCentury: 'Last century',
  nextCentury: 'Next century',
}

const timePickerLocale = {
  placeholder: 'Select time',
}

const datePickerLocale = {
  lang: {
    placeholder: 'Select date',
    rangePlaceholder: ['Start date', 'End date'],
    ...calendarLocale,
  },
  timePickerLocale,
}

export default {
  locale: 'ru',
  global: {
    placeholder: 'Please select',
  },
  Pagination: {
    items_per_page: '/ page',
    jump_to: 'Goto',
    jump_to_confirm: 'confirm',
    page: '',

    prev_page: 'Предыдущая страница',
    next_page: 'Следующая страница',
    prev_5: 'Предыдущие 5 страниц',
    next_5: 'Следующие 5 страниц',
    prev_3: 'Предыдущие 3 страницы',
    next_3: 'Следующие 3 страницы',
  },
  DatePicker: datePickerLocale,
  TimePicker: timePickerLocale,
  Calendar: datePickerLocale,
  Table: {
    filterTitle: 'Filter menu',
    filterConfirm: 'OK',
    filterReset: 'Сброс',
    selectAll: 'Select current page',
    selectInvert: 'Invert current page',
    sortTitle: 'Сортировка',
  },
  Modal: {
    okText: 'OK',
    cancelText: 'Отмена',
    justOkText: 'OK',
  },
  Popconfirm: {
    okText: 'OK',
    cancelText: 'Отмена',
  },
  Transfer: {
    titles: ['', ''],
    searchPlaceholder: 'Search here',
    itemUnit: 'item',
    itemsUnit: 'items',
  },
  Upload: {
    uploading: 'Загрузка...',
    removeFile: 'Удалить файл',
    uploadError: 'Ошибка загрузки',
    previewFile: 'Предпросмотр файла',
  },
  Empty: {
    description: 'Нет данных',
  },
}
