export default {
  header: 'Вход для клиентов',
  login: {
    placeholder: 'Логин / e-mail',
    validation: {
      empty: 'Обязательное поле',
    },
  },
  password: {
    placeholder: 'Пароль',
    validation: {
      empty: 'Обязательное поле',
    },
  },
  validation: {
    incorrect: 'Неверный логин или пароль',
  },
  signIn: 'Войти',
}
