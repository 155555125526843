import React from 'react'
import PropTypes from 'prop-types'

import {
  Field,
  reduxForm,
  SubmissionError,
} from 'redux-form'

import cx from 'classnames'

import {
  Form,
  Button,
  I18n,
  Alert,
} from 'components'

import { FunctionUtils } from 'utils'

import validate from './validation'

import styles from './styles.module.scss'

const errorRenderer = error => {
  if (error) {
    return (
      <div className={ styles['form-item'] }>
        <Alert
          className={ cx(styles['form-item'], styles.alert) }
          type="error"
          message={ error }
        />
      </div>
    )
  }

  return null
}

const AuthForm = ({
  className,

  handleSubmit,
  submitting,
  pristine,
  invalid,
  error,
}) => (
  <Form
    onSubmit={ handleSubmit }
    className={ cx(styles['auth-form'], className) }
  >
    <div className={ styles.content }>
      <I18n.Translate value="auth.header" tag="h1" className={ styles.header } />
      { errorRenderer(error) }
      <Field
        className={ styles['form-item'] }
        name="login"
        component={ Form.Input }
        placeholder={ I18n.Helpers.t('auth.login.placeholder') }
        autoComplete="username"
        icon="user"
        hasFeedback
      />
      <Field
        className={ styles['form-item'] }
        name="password"
        component={ Form.Input }
        placeholder={ I18n.Helpers.t('auth.password.placeholder') }
        type="password"
        autoComplete="current-password"
        icon="lock"
        hasFeedback
      />
      <Button
        className={ cx(styles['form-item'], styles['form-button']) }
        type="primary"
        disabled={ invalid || submitting || pristine }
        loading={ submitting }
        htmlType="submit"
      >
        <I18n.Translate value="auth.signIn" />
      </Button>
    </div>
  </Form>
)

AuthForm.propTypes = {
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  error: PropTypes.string,

  handleSubmit: PropTypes.func.isRequired,

  className: PropTypes.string,

  onFormSubmit: PropTypes.func, // eslint-disable-line
}

AuthForm.defaultProps = {
  error: null,

  className: '',

  onFormSubmit: FunctionUtils.stub(),
}

export default FunctionUtils.compose(
  reduxForm({
    form: 'AuthForm',
    asyncValidate: values => validate(values),
    onSubmit: (values, dispatch, props) => props.onFormSubmit(values).catch(() => {
      props.change('password', '')

      throw new SubmissionError({
        _error: I18n.Helpers.t('auth.validation.incorrect'),
      })
    }),
  }),
)(AuthForm)
