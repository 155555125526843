import React from 'react'
import PropTypes from 'prop-types'

import cx from 'classnames'

import { Layout, Grid } from 'components'

import styles from './styles.module.scss'

const Header = ({ className, children }) => (
  <Layout.Header className={ cx(styles.header, className) }>
    <Grid.Row type="flex" align="middle">
      <Grid.Col span={ 6 }>
        <img
          alt=""
          className={ styles.logo }
          src={ `${process.env.PUBLIC_URL}/logo.png` }
        />
      </Grid.Col>
      <Grid.Col span={ 18 }>{children}</Grid.Col>
    </Grid.Row>
  </Layout.Header>
)

Header.propTypes = {
  className: PropTypes.string,

  children: PropTypes.node,
}

Header.defaultProps = {
  className: '',

  children: null,
}

export default Header
