import { ObjectUtils, QueryUtils } from 'utils'

export const request = (method, url, options) => {
  const {
    query,
    data,
    headers,
  } = options

  const queryString = QueryUtils.toQueryString(query)

  return fetch(`${url}${queryString}`, {
    method,
    headers: {
      ...ObjectUtils.omitEmpty(headers),
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(data),
  }).then(response => response.json().then(json => ({
    ok: response.ok,
    statusCode: response.status,
    json,
  }))).then(response => {
    if (response.ok) {
      return response.json
    }

    throw Object.assign(
      new Error(),
      {
        code: response.statusCode,
        body: response.json,
      },
    )
  })
}
