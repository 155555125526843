import { handleActions } from 'redux-actions'

import produce from 'immer'

import {
  slideChangeStarted,

  slideChangeFailed,
  slideChangeFinished,

  resetProgressStarted,
  resetProgressFailed,
  resetProgressFinished,

} from './actions'

const initialState = {
  loading: false,
  data: [],
  error: false,
}

const reducer = handleActions({
  [slideChangeStarted]: state => produce(state, next => {
    next.loading = true
  }),
  [slideChangeFailed]: (state, action) => produce(state, next => {
    next.error = action.payload
  }),
  [slideChangeFinished]: state => produce(state, next => {
    next.loading = false
  }),

  [resetProgressStarted]: state => produce(state, next => {
    next.loading = true
  }),

  [resetProgressFailed]: (state, action) => produce(state, next => {
    next.error = action.payload
  }),
  [resetProgressFinished]: state => produce(state, next => {
    next.loading = false
  }),
}, initialState)

export default reducer
