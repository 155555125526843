import { combineReducers } from 'redux'

import { all, fork } from 'redux-saga/effects'

import appReducer, { actions as appActions, saga as appSaga, selectors as appSelectors } from './app'
import authReducer, { actions as authActions, saga as authSaga, selectors as authSelectors } from './auth'
import userReducer, { actions as userActions, saga as userSaga, selectors as userSelectors } from './user'
import classesReducer,
{ actions as classesActions, saga as classesSaga, selectors as classesSelectors } from './classes'
import subjectsReducer,
{ actions as subjectsActions, saga as subjectsSaga, selectors as subjectsSelectors } from './subjects'
import weeksReducer,
{ actions as weeksActions, saga as weeksSaga, selectors as weeksSelectors } from './weeks'
import presentationReducer,
{ actions as presentationActions, saga as presentationSaga, selectors as presentationSelectors } from './presentation'

import lessonsReducer,
{ actions as lessonsActions, saga as lessonsSaga, selectors as lessonsSelectors } from './lessons'
import routerReducer from './router'
import formReducer from './form'

const reducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  user: userReducer,
  classes: classesReducer,
  lessons: lessonsReducer,
  weeks: weeksReducer,
  presentation: presentationReducer,
  subjects: subjectsReducer,
  router: routerReducer,
  form: formReducer,
})

const actions = {
  App: appActions,
  Auth: authActions,
  User: userActions,
  Classes: classesActions,
  Subjects: subjectsActions,
  Weeks: weeksActions,
  Presentation: presentationActions,
  Lessons: lessonsActions,
}

const selectors = {
  App: appSelectors,
  Auth: authSelectors,
  User: userSelectors,
  Classes: classesSelectors,
  Subjects: subjectsSelectors,
  Weeks: weeksSelectors,
  Presentation: presentationSelectors,
  Lessons: lessonsSelectors,
}

function* saga() {
  yield all([
    fork(appSaga),
    fork(authSaga),
    fork(userSaga),
    fork(classesSaga),
    fork(subjectsSaga),
    fork(weeksSaga),
    fork(presentationSaga),
    fork(lessonsSaga),
  ])
}

export {
  reducer as default,
  saga,
  selectors as Selectors,
  actions as Actions,
}
