import React from 'react'
import PropTypes from 'prop-types'

import { Form as AntdForm } from 'antd'

import { FunctionUtils } from 'utils'

import Input from './Input'

const Form = ({
  className, onSubmit, ...props
}) => (
  <AntdForm className={ className } onSubmit={ onSubmit } { ...props } />
)

Form.propTypes = {
  className: PropTypes.string,

  onSubmit: PropTypes.func,
}

Form.defaultProps = {
  className: '',

  onSubmit: FunctionUtils.stub(),
}

Form.Input = Input

export default Form
