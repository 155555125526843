import { handleActions } from 'redux-actions'

import produce from 'immer'

import { AppConstants } from 'consts'

import {
  setLocale,

  initSuccessed,
} from './actions'

const initialState = {
  isInitialized: false,
  locale: AppConstants.DEFAULT_LOCALE,
}

const reducer = handleActions({
  [initSuccessed]: state => produce(state, next => {
    next.isInitialized = true
  }),
  [setLocale]: (state, action) => produce(state, next => {
    next.locale = action.payload
  }),
}, initialState)

export default reducer
