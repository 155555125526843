import { createAction } from 'redux-actions'

import { ReduxUtils } from 'utils'

const fetchStarted = createAction('CLASSES/FETCH_STARTED')
const fetchGetSuccessed = createAction('CLASSES/FETCH_GET_SUCCESSED')
const fetchAddSuccessed = createAction('CLASSES/FETCH_ADD_SUCCESSED')
const fetchEditSuccessed = createAction('CLASSES/FETCH_EDIT_SUCCESSED')
const fetchDelSuccessed = createAction('CLASSES/FETCH_DELETE_SUCCESSED')
const fetchFailed = createAction('CLASSES/FETCH_FAILED')
const fetchFinished = createAction('CLASSES/FETCH_FINISHED')

const getClasses = ReduxUtils.waitForAction(
  createAction('CLASSES/FETCH_GET_CLASSES'),
  fetchGetSuccessed,
  fetchFailed,
)

const addClass = ReduxUtils.waitForAction(
  createAction('CLASSES/FETCH_ADD_CLASS', (number, literal) => ({
    number,
    literal,
  })),
  fetchAddSuccessed,
  fetchFailed,
)

const deleteClass = ReduxUtils.waitForAction(
  createAction('CLASSES/FETCH_DELETE_CLASS', classID => ({
    classID,
  })),
  fetchDelSuccessed,
  fetchFailed,
)

const editClass = ReduxUtils.waitForAction(
  createAction('CLASSES/FETCH_EDIT_CLASS', (classID, number, literal) => ({
    classID,
    number,
    literal,
  })),
  fetchEditSuccessed,
  fetchFailed,
)

export {
  getClasses,
  addClass,
  deleteClass,
  editClass,
  fetchStarted,
  fetchGetSuccessed,
  fetchAddSuccessed,
  fetchDelSuccessed,
  fetchEditSuccessed,
  fetchFailed,
  fetchFinished,
}
