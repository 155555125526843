import React from 'react'
import PropTypes from 'prop-types'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Router } from 'components'

import { Actions, Selectors } from 'ducks'

import { FunctionUtils } from 'utils'

import Header from './Header'
import Content from './Content'
import Presentation from './Presentation'
import Footer from './Footer'

import styles from './styles.module.scss'

const Workspace = ({ user }) => (
  <div className={ styles.workspace }>
    <Header className={ styles.header } user={ user } />
    <div className={ styles.content }>
      <Router.Switch>
        <Router.Route
          exact
          path="/classes/:classId/subjects/:subjectId/lessons/:lessonId/presentation"
          component={ Presentation }
        />
        <Router.Route path="/" component={ Content } />
      </Router.Switch>
    </div>
    <Footer className={ styles.footer } />
  </div>
)

Workspace.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    avatar: PropTypes.string,
  }).isRequired,
}

const mapStateToProps = state => ({
  user: Selectors.User.get(state),
})

const mapDispatchToProps = dispatch => bindActionCreators({
  signOut: Actions.User.fetch,
}, dispatch)

export default FunctionUtils.compose(
  Router.withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Workspace)
