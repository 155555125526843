import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Router } from 'components'

import { Actions, Selectors } from 'ducks'

import { FunctionUtils } from 'utils'

class SignOut extends PureComponent {
  static propTypes = {
    isAuthorized: PropTypes.bool.isRequired,

    signOut: PropTypes.func.isRequired,
  }

  componentDidMount() {
    this.props.signOut()
  }

  render() {
    const {
      isAuthorized,
    } = this.props

    if (!isAuthorized) {
      return (
        <Router.Redirect to="/" />
      )
    }

    return null
  }
}

const mapStateToProps = state => ({
  isAuthorized: Selectors.Auth.isAuthorized(state),
})

const mapDispatchToProps = dispatch => bindActionCreators({
  signOut: Actions.Auth.signOut,
}, dispatch)

export default FunctionUtils.compose(
  connect(mapStateToProps, mapDispatchToProps),
)(SignOut)
