import React from 'react'

const EmptyLesson = () => (
  <div>
    <h1>Занятия</h1>
    <span>Для отображения уроков выберите класс и предмет...</span>
  </div>
)

export default EmptyLesson
