import React from 'react'
import PropTypes from 'prop-types'

import {
  Route,
  Redirect,
} from 'react-router'

import { connect } from 'react-redux'

import { Selectors } from 'ducks'

import { FunctionUtils } from 'utils'

const createRouteRenderer = (Component, props) => componentProps => {
  if (props.isAuthorized) {
    return (
      <Component { ...componentProps } />
    )
  }

  const redirectPath = {
    pathname: '/sign-in',
    state: {
      redirect: componentProps.location,
    },
  }

  return (
    <Redirect to={ redirectPath } />
  )
}

const ProtectedRoute = ({ component, isAuthorized, ...routeProps }) => (
  <Route
    { ...routeProps }
    render={ createRouteRenderer(component, { isAuthorized }) }
  />
)

ProtectedRoute.propTypes = {
  component: Route.propTypes.component,

  isAuthorized: PropTypes.bool.isRequired,
}

ProtectedRoute.defaultProps = {
  component: null,
}

const mapStateToProps = state => ({
  isAuthorized: Selectors.Auth.isAuthorized(state),
})

export default FunctionUtils.compose(
  connect(mapStateToProps),
)(ProtectedRoute)
