import React, { PureComponent } from 'react'

import PropTypes from 'prop-types'

import {
  Router, AvatarClasses as Avatar, I18n,
} from 'components'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Actions, Selectors } from 'ducks'

import { FunctionUtils } from 'utils'
import styles from './styles.module.scss'
import ModalEdit from './ModalEdit'
import ModalAdd from './ModalAdd'

class SiderHeader extends PureComponent {
  static propTypes = {
    delClass: PropTypes.func.isRequired,
    addClass: PropTypes.func.isRequired,
    editClass: PropTypes.func.isRequired,
    getClasses: PropTypes.func.isRequired,
    classes: PropTypes.arrayOf(PropTypes.shape({
      number: PropTypes.string.isRequired,
      literal: PropTypes.string,
    })).isRequired,
  }

  componentDidMount() {
    this.props.getClasses()
  }

  renderClasses = classes => {
    if (!classes) return null
    const view = classes.map(item => (
      <Router.NavLink to={ `/classes/${item.id}` } key={ item.number + item.literal }>
        <Avatar number={ item.number } literal={ item.literal } />
      </Router.NavLink>

    ))
    return view
  }

  render() {
    const { classes } = this.props


    return (
      <div className={ styles['side-header'] }>
        <div className={ styles.header }>
          <h1 className={ styles.left }>
            <I18n.Translate
              value="sideHeader.classesHeader"
            />
          </h1>
          <ModalEdit classes={ classes } delClass={ this.props.delClass } editClass={ this.props.editClass } />
        </div>

        {this.renderClasses(classes)}

        <ModalAdd addClass={ this.props.addClass } />
      </div>

    )
  }
}

const mapStateToProps = state => ({
  classes: Selectors.Classes.getClasses(state),

})

const mapDispatchToProps = dispatch => bindActionCreators({
  getClasses: Actions.Classes.getClasses,
  addClass: Actions.Classes.addClass,
  editClass: Actions.Classes.editClass,
  delClass: Actions.Classes.deleteClass,
}, dispatch)

export default FunctionUtils.compose(
  Router.withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(SiderHeader)
