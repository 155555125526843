import React from 'react'

import { setAutoFreeze } from 'immer'

import { App } from 'containers'

import { AppConstants } from 'consts'
import { ReactUtils } from 'utils'

import { configureStore } from 'store'

import './index.css'

setAutoFreeze(false)

const { store } = configureStore({})

ReactUtils.render(<App store={ store } />, document.querySelector(AppConstants.APP_ELEMENT))
