export default class UserService {
  constructor(apiService) {
    this._apiService = apiService
  }

  _mapUser = user => ({
    id: user.user_id,
    name: user.username,
    email: user.email,
    avatar: user.avatar,
  })

  getUser() {
    return this._apiService.get('/client').then(user => this._mapUser(user))
  }
}
