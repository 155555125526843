import {
  takeLatest, put, call,
} from 'redux-saga/effects'

import { WeeksService } from 'services'

import {
  fetch,

  fetchStarted,
  fetchSuccessed,
  fetchFailed,
  fetchFinished,
} from './actions'

function* fetchTask(action) {
  yield put(fetchStarted())

  try {
    const weeks = yield call([WeeksService, 'getWeeks'], action.payload)
    yield put(fetchSuccessed(weeks))
  } catch (err) {
    yield put(fetchFailed(err))
  }

  yield put(fetchFinished())
}

export default function* () {
  yield takeLatest(fetch, fetchTask)
}
