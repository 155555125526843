import React, { PureComponent } from 'react'

import PropTypes from 'prop-types'

import { Avatar } from 'antd'
import { Router } from 'components'
import styles from './styles.module.scss'

class AvatarClasses extends PureComponent {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        classId: PropTypes.number,
      }).isRequired,
    }),
    number: PropTypes.string.isRequired,
    literal: PropTypes.string,
  }

  static defaultProps = {
    literal: '',
    match: null,
  }

  renderInfo = (number, literal) => (
    <div className={ styles.avatar }>

      <span className={ styles.info }>
        {number}
        {literal.toUpperCase()}
      </span>

    </div>
  )

  render() {
    const { number, literal, match } = this.props
    return (
      <Avatar style={ { backgroundColor: match ? 'red' : null, verticalAlign: 'middle', margin: '10px' } } size="large">
        {this.renderInfo(number, literal)}
      </Avatar>
    )
  }
}


export default Router.withRouter(AvatarClasses)
