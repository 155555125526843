import React, { PureComponent } from 'react'

import {
  Router, Layout, Profile, EmptyLesson as Empty,
} from 'components'

import ContentSubjects from './ContentSubjects'
import SiderHeader from './SiderHeader'
import SiderSubjects from './SiderSubjects'
import styles from './styles.module.scss'

class Content extends PureComponent {
  render() {
    return (
      <Layout className={ styles['content-form'] }>
        <Layout.Sider width={ 400 }>
          <div className={ styles.side }>
            <SiderHeader />

            <Router.Route
              path="/classes/:classId"
              component={ SiderSubjects }
            />
          </div>
        </Layout.Sider>
        <Layout.Content className={ styles.content }>
          <Router.Switch>
            <Router.Route exact path="/profile" component={ Profile } />
            <Router.Route exact path="/classes/:classId/subjects/:subjectId" component={ ContentSubjects } />
            <Router.Route path="/" component={ Empty } />
          </Router.Switch>
        </Layout.Content>
      </Layout>
    )
  }
}


export default Content
