import { I18n } from 'components'

import { ValidationUtils } from 'utils'

export default values => ValidationUtils.validateAsync(values, {
  login: {
    presence: {
      allowEmpty: false,
      message: I18n.Helpers.t('auth.login.validation.empty'),
    },
  },
  password: {
    presence: {
      allowEmpty: false,
      message: I18n.Helpers.t('auth.password.validation.empty'),
    },
  },
}, { fullMessages: false })
