export default class AuthService {
  constructor(apiService) {
    this._apiService = apiService
  }

  signIn(login, password) {
    return this._apiService.post('/auth/login', {
      email: login,
      password,
    }).then(response => response.token).catch(e => {
      throw e
    })
  }
}
