import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Router } from 'components'

import { Actions, Selectors } from 'ducks'

import { FunctionUtils } from 'utils'
import AuthForm from './AuthForm'

import styles from './styles.module.scss'

const SignIn = ({ isAuthorized, signIn }) => {
  if (isAuthorized) {
    return (
      <Router.Redirect to="/" />
    )
  }

  return (
    <div className={ styles['sign-in'] }>
      <AuthForm
        className={ styles.content }
        onFormSubmit={ values => signIn(values.login, values.password) }
      />
    </div>
  )
}

SignIn.propTypes = {
  isAuthorized: PropTypes.bool.isRequired,

  signIn: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  isAuthorized: Selectors.Auth.isAuthorized(state),
})

const mapDispatchToProps = dispatch => bindActionCreators({
  signIn: Actions.Auth.signIn,
}, dispatch)

export default FunctionUtils.compose(
  connect(mapStateToProps, mapDispatchToProps),
)(SignIn)
