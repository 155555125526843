import React from 'react'
import PropTypes from 'prop-types'

import cx from 'classnames'

import { FunctionUtils } from 'utils'

import {
  Layout, Grid, Button, Icon, I18n, Router,
} from 'components'

import Pagination from './Pagination'

import styles from './styles.module.scss'

const PresentationFooter = ({
  className,
  slide,
  total,
  onChange,
  onPrev,
  onNext,
  linkBack,
  onFullscreenChange,
  isLastFragmentCurrentSlide,
  isFirstFragmentCurrentSlide,
}) => (
  <Layout.Footer className={ cx(styles.footer, className) }>
    <Grid.Row type="flex" align="middle">
      <Grid.Col span={ 8 }>

        <Router.Link to={ `/classes/${linkBack.classId}/subjects/${linkBack.subjectId}` }>

          <Button>
            <Icon type="left" />
            <I18n.Translate value="presentation.back" />
          </Button>
        </Router.Link>
      </Grid.Col>
      <Grid.Col className={ styles['pagination-col'] } span={ 8 }>
        <Pagination
          key={ slide } // Force react to re-render component
          slide={ slide }
          total={ total }
          onChange={ onChange }
          isLastFragmentCurrentSlide={ isLastFragmentCurrentSlide }
          isFirstFragmentCurrentSlide={ isFirstFragmentCurrentSlide }
          onPrev={ onPrev }
          onNext={ onNext }
        />
      </Grid.Col>
      <Grid.Col className={ styles['fullscreen-col'] } span={ 8 }>
        <Button
          icon="fullscreen"
          title={ I18n.Helpers.t('presentation.fullscreen') }
          onClick={ () => onFullscreenChange(true) }
        />
      </Grid.Col>
    </Grid.Row>
  </Layout.Footer>
)

PresentationFooter.propTypes = {
  className: PropTypes.string,
  linkBack: PropTypes.shape({
    classId: PropTypes.string.isRequired,
  }).isRequired,
  slide: PropTypes.number,
  total: PropTypes.number,
  isLastFragmentCurrentSlide: PropTypes.bool,
  isFirstFragmentCurrentSlide: PropTypes.bool,
  onChange: PropTypes.func,
  onPrev: PropTypes.func,
  onNext: PropTypes.func,
  onFullscreenChange: PropTypes.func,
}

PresentationFooter.defaultProps = {
  className: '',
  isLastFragmentCurrentSlide: true,
  slide: 1,
  total: 1,
  isFirstFragmentCurrentSlide: FunctionUtils.stub(),
  onChange: FunctionUtils.stub(),
  onPrev: FunctionUtils.stub(),
  onNext: FunctionUtils.stub(),
  onFullscreenChange: FunctionUtils.stub(),
}

export default PresentationFooter
