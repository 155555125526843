import React, { PureComponent } from 'react'

import PropTypes from 'prop-types'

import { Select } from 'antd'


const renderOption = item => (
  <Select.Option key={ item.id } value={ item.id }>{item.name}</Select.Option>
)

const renderOptions = items => items.map(item => renderOption(item))

export default class SelectWeeks extends PureComponent {
  static propTypes = {
    changeWeek: PropTypes.func.isRequired,

    week: PropTypes.number,
    weeks: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
    })).isRequired,
  }

  static defaultProps = {
    week: undefined,
  }

  handleChange = item => {
    this.props.changeWeek(item)
  }

  render() {
    const { weeks, week } = this.props

    return (
      <Select defaultValue="Выбрать неделю" value={ week } style={ { width: '30%' } } onChange={ this.handleChange }>
        {renderOptions(weeks)}
      </Select>
    )
  }
}
