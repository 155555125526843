import React, { PureComponent } from 'react'

import PropTypes from 'prop-types'

import { Modal, Button, Icon } from 'antd'
import { I18n } from 'components'
import DynamicForm from './DynamicForm'
import styles from './styles.module.scss'


export default class ModalEdit extends PureComponent {
  static propTypes = {
    delClass: PropTypes.func.isRequired,
    editClass: PropTypes.func.isRequired,
    classes: PropTypes.arrayOf(PropTypes.shape({
      number: PropTypes.string.isRequired,
      literal: PropTypes.string,
    })).isRequired,
  }


  state = { visible: false }

  modalShow = () => {
    this.setState({
      visible: true,
    })
  }

  handleOk = e => {
    this.setState({
      visible: false,
    })
  }

  handleCancel = e => {
    this.setState({
      visible: false,
    })
  }

  render() {
    const { classes } = this.props

    const formKey = classes.map(_class => `${_class.number}${_class.literal}`).join('')

    return (
      <div className={ styles.modal }>
        {/* eslint-disable-next-line  */}
        <span className={ styles.edit } onClick={ this.modalShow }>
          <Icon type="edit" />
          <I18n.Translate
            value="sideHeader.classesEdit"
          />
        </span>
        <Modal
          title={ (
            <I18n.Translate
              value="sideHeader.classesEditHeader"
            />
          ) }
          visible={ this.state.visible }
          onOk={ this.handleOk }
          onCancel={ this.handleCancel }
          footer={ [
            <Button key="back" onClick={ this.handleCancel }>
              <I18n.Translate
                value="sideHeader.classesEditClose"
              />
            </Button>,
          ] }
        >
          <DynamicForm
            key={ formKey }
            delClass={ this.props.delClass }
            editClass={ this.props.editClass }
            classes={ classes }
          />
        </Modal>
      </div>


    )
  }
}
