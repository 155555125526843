import { APIUtils } from 'utils'

import { createHeaders } from './utils'

export default class APIService {
  constructor(baseUrl) {
    this._baseUrl = baseUrl
    this._accessToken = null

    this._errorHandlers = []
  }

  setAccessToken(token) {
    this._accessToken = token
  }

  addErrorHandler(handler) {
    this._errorHandlers.push(handler)

    return () => {
      this._errorHandlers = this._errorHandlers.filter(_handler => _handler !== handler)
    }
  }

  _invokeErrorHandlers(error) {
    return Promise.all(this._errorHandlers.map(handler => handler(error))).then(() => Promise.resolve(error))
  }

  get(url, query) {
    return APIUtils.request('GET', `${this._baseUrl}${url}`, {
      query,
      headers: createHeaders(this._accessToken),
    }).catch(e => this._invokeErrorHandlers(e).then(obj => obj.body))
  }

  post(url, params, query = {}) {
    return APIUtils.request('POST', `${this._baseUrl}${url}`, {
      query,
      data: params,
      headers: createHeaders(this._accessToken),
    }).catch(e => this._invokeErrorHandlers(e).then(obj => obj.body))
  }

  put(url, params, query = {}) {
    return APIUtils.request('PUT', `${this._baseUrl}${url}`, {
      query,
      data: params,
      headers: createHeaders(this._accessToken),
    }).catch(e => this._invokeErrorHandlers(e).then(obj => obj.body))
  }

  patch(url, params, query = {}) {
    return APIUtils.request('PATCH', `${this._baseUrl}${url}`, {
      query,
      data: params,
      headers: createHeaders(this._accessToken),
    }).catch(e => this._invokeErrorHandlers(e).then(obj => obj.body))
  }

  delete(url, params, query = {}) {
    return APIUtils.request('DELETE', `${this._baseUrl}${url}`, {
      query,
      data: params,
      headers: createHeaders(this._accessToken),
    }).catch(e => this._invokeErrorHandlers(e).then(obj => obj.body))
  }
}
