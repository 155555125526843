import { createAction } from 'redux-actions'

import { ReduxUtils } from 'utils'

const fetchStarted = createAction('USER/FETCH_STARTED')
const fetchSuccessed = createAction('USER/FETCH_SUCCESSED')
const fetchFailed = createAction('USER/FETCH_FAILED')
const fetchFinished = createAction('USER/FETCH_FINISHED')

const fetch = ReduxUtils.waitForAction(
  createAction('USER/FETCH'),
  fetchSuccessed,
  fetchFailed,
)

export {
  fetch,

  fetchStarted,
  fetchSuccessed,
  fetchFailed,
  fetchFinished,
}
