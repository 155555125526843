import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { Scrollbars } from 'react-custom-scrollbars'

export default class Scrollable extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
  }

  render() {
    const {
      children,
      ...props
    } = this.props

    return (
      <Scrollbars autoHide { ...props }>
        { children }
      </Scrollbars>
    )
  }
}
