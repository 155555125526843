
export default class ClassesService {
  constructor(apiService) {
    this._apiService = apiService
  }

  _mapClass = _class => ({
    id: _class.id,
    number: _class.number,
    literal: _class.literal,
  })

  _mapClasses = classes => classes.map(_class => this._mapClass(_class))

  _mapClassAdd = _class => ({
    id: _class.id,
    number: _class.number,
    literal: _class.literal.literal,
  })

  _mapClassEdit = _class => ({
    id: _class.id,
    number: _class.number,
    literal: _class.literal.literal,
  })

  getClasses() {
    return this._apiService.get('/class_numbers').then(classes => this._mapClasses(classes.data))

    // return new Promise(resolve => {
    //   const classes = this._mapClasses(ClassesMock)

    //   setTimeout(() => {
    //     resolve(classes)
    //   }, Math.round(Math.random() * 2 + 1))
    // })
  }

  addClass(number, literal) {
    return this._apiService.post('/class_numbers', {
      number,
      literal,
    }).then(_class => this._mapClassAdd(_class.data)).catch(e => {
      throw e
    })
  }

  deleteClass(classID) {
    return this._apiService.delete(`/class_numbers/${classID}`).then(() => (classID)).catch(e => {
      throw e
    })
  }

  editClass(classID, number, literal) {
    return this._apiService.put(`/class_numbers/${classID}`, {
      number,
      literal,
    }).then(response => this._mapClassEdit(response.data)).catch(e => {
      throw e
    })
  }
}
