import React from 'react'

import { Spinner, I18n } from 'components'

import styles from './styles.module.scss'

const Loading = () => (
  <div className={ styles.loading }>
    <Spinner
      className={ styles.spinner }
      size="large"
      tip={ I18n.Helpers.t('common.loading') }
    />
  </div>
)

export default Loading
