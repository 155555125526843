import {
  takeLatest, put, call,
} from 'redux-saga/effects'

import { UserService } from 'services'

import {
  fetch,

  fetchStarted,
  fetchSuccessed,
  fetchFailed,
  fetchFinished,
} from './actions'

function* fetchTask() {
  yield put(fetchStarted())

  try {
    const client = yield call([UserService, 'getUser'])

    yield put(fetchSuccessed(client))
  } catch (err) {
    yield put(fetchFailed(err))
  }

  yield put(fetchFinished())
}

export default function* () {
  yield takeLatest(fetch, fetchTask)
}
