import validate from 'validate.js'

validate.validators.checked = value => {
  if (value) {
    return null
  }
  return 'must be checked'
}

export default class ValidationUtils {
  static validate = (values, format) => validate(values, format, { fullMessages: false }) || {}

  static validateAsync = (values, format) => validate.async(values, format, { fullMessages: false }).then(() => ({}))
}
