import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import produce from 'immer'

import {
  Form, Input, Icon, Popconfirm,
} from 'antd'
import styles from './styles.module.scss'

class DynamicForm extends PureComponent {
  static propTypes = {
    editClass: PropTypes.func.isRequired,
    delClass: PropTypes.func.isRequired,
    classes: PropTypes.arrayOf(PropTypes.shape({
      number: PropTypes.string.isRequired,
      literal: PropTypes.string,
    })).isRequired,
  }

  state = {
    classes: this.props.classes,
  }

  handleEdit = id => {
    const _class = this.state.classes.find(item => item.id === id)

    this.props.editClass(id, _class.number, _class.literal)
  }

  handleChangeLiteral = (id, literal) => {
    this.setState(state => produce(state, next => {
      const index = state.classes.findIndex(_class => _class.id === id)

      next.classes[index].literal = literal
    }))
  }

  handleRemove = id => {
    this.props.delClass(id)
  }

  renderForm = classes => classes.map(_class => (
    <Form.Item key={ _class.id }>
      <span className={ styles['edit-label'] }>{ _class.number }</span>
      <Input
        type="text"
        onChange={ e => this.handleChangeLiteral(_class.id, e.target.value) }
        defaultValue={ _class.literal }
        className={ styles['edit-input'] }
      />
      { classes.length > 1 && (
        <Popconfirm
          title="Вы действительно хотите удалить этот класс?"
          onConfirm={ () => this.handleRemove(_class.id) }
          okText="Да"
          cancelText="Нет"
        >
          <Icon
            className={ styles['dynamic-delete-button'] }
            type="minus-circle-o"
          />
        </Popconfirm>
      ) }
      <Popconfirm
        title="Применить изменения к этому классу?"
        onConfirm={ () => this.handleEdit(_class.id) }
        okText="Да"
        cancelText="Нет"
      >
        <Icon
          className={ styles['dynamic-check-button'] }
          type="check-circle-o"
        />
      </Popconfirm>
    </Form.Item>
  ))

  render() {
    const { classes } = this.state
    return (
      <Form layout="inline" onSubmit={ this.handleSubmit }>
        {this.renderForm(classes)}
      </Form>
    )
  }
}

export default DynamicForm
