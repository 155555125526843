import React, { PureComponent } from 'react'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Actions } from 'ducks'

import PropTypes from 'prop-types'

import {
  Layout,
  PresentationView,
  Router,
} from 'components'

import {
  FunctionUtils,
  KeyUtils,
  QueryUtils,
} from 'utils'

import Header from './Header'
import Footer from './Footer'

import styles from './styles.module.scss'

class Presentation extends PureComponent {
  static propTypes = {
    location: PropTypes.shape({
      search: PropTypes.string.isRequired,
    }).isRequired,
    slideChange: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        lessonId: PropTypes.string.isRequired,

      }),
    }).isRequired,
  }

  static getDerivedStateFromProps(props, state) {
    const {
      location: {
        search: queryString,
      },
    } = props


    let startingSlide = QueryUtils.fromQueryString(queryString).from

    if (typeof startingSlide !== 'number') {
      startingSlide = 1
    }

    return {
      currentSlide: state.currentSlide || startingSlide,
    }
  }

  presentation = React.createRef()

  state = {
    currentSlide: null,
    total: 1,
    isLastFragmentCurrentSlide: false,
    isFirstFragmentCurrentSlide: true,
    isFinish: false,
    fullscreen: false,
  }

  componentDidMount() {
    window.addEventListener('fullscreenchange', this.handleFullscreenChange, false)
    window.addEventListener('webkitfullscreenchange', this.handleFullscreenChange, false)
    window.addEventListener('mozfullscreenchange', this.handleFullscreenChange, false)
    window.addEventListener('keydown', this.handleArrowPress, false)
  }

  componentWillUnmount() {
    window.removeEventListener('fullscreenchange', this.handleFullscreenChange)
    window.removeEventListener('webkitfullscreenchange', this.handleFullscreenChange)
    window.removeEventListener('mozfullscreenchange', this.handleFullscreenChange)
    window.removeEventListener('keydown', this.handleArrowPress)
  }

  handleArrowPress = e => {
    const event = window.event ? window.event : e

    switch (KeyUtils.fromCharCode(event.keyCode)) {
      case 'page up':
      case 'up':
      case 'left': {
        this.handlePrev()

        break
      }
      case 'page down':
      case 'down':
      case 'space':
      case 'right': {
        this.handleNext()

        break
      }
      default:
    }
  }

  handleFinishLesson = () => {
    this.props.slideChange(this.state.currentSlide, this.props.match.params.lessonId, true)
  }

  handleIsLastFragmentChange = val => {
    if (val && this.state.currentSlide === this.state.total) {
      this.setState({
        isFinish: true,
      })
    }
    this.setState({ isLastFragmentCurrentSlide: val })
  }

  handleIsFirstFragmentChange = val => {
    this.setState({ isFirstFragmentCurrentSlide: val })
  }

  handleFullscreenChange = () => {
    if (this.state.fullscreen) {
      this.handleFullscreen(false)
    }
  }

  handleSlideChange = slide => {
    this.props.slideChange(slide, this.props.match.params.lessonId, false)
    this.setState({ currentSlide: slide })
  }

  handleReady = total => {
    this.setState({ total })
  }

  handleFullscreen = value => {
    this.setState({ fullscreen: value })
  }

  handlePrev = () => {
    this.presentation.current.prev()
  }

  handleNext = () => {
    this.presentation.current.next()
  }

  render() {
    const {
      currentSlide,
      total,
      fullscreen,
      isLastFragmentCurrentSlide,
      isFirstFragmentCurrentSlide,
      isFinish,
    } = this.state

    return (
      <Layout className={ styles.presentation }>
        <Header
          className={ styles.header }
          finishLesson={ this.handleFinishLesson }
          isFinish={ isFinish }
          linkBack={ this.props.match.params }
        />
        <Layout.Content className={ styles.content }>
          <PresentationView
            ref={ this.presentation }
            className={ styles.presentation }
            url={ `/files/presentations/${this.props.match.params.lessonId}/index.html` }
            slide={ currentSlide }
            fullscreen={ fullscreen }
            onReady={ this.handleReady }
            onSlideChanged={ this.handleSlideChange }
            onFullscreenChange={ this.handleFullscreen }
            handleIsLastFragmentChange={ this.handleIsLastFragmentChange }
            handleIsFirstFragmentChange={ this.handleIsFirstFragmentChange }
          />
        </Layout.Content>
        <Footer
          className={ styles.footer }
          slide={ currentSlide }
          total={ total }
          linkBack={ this.props.match.params }
          isLastFragmentCurrentSlide={ isLastFragmentCurrentSlide }
          isFirstFragmentCurrentSlide={ isFirstFragmentCurrentSlide }
          onChange={ this.handleSlideChange }
          onFullscreenChange={ this.handleFullscreen }
          onNext={ this.handleNext }
          onPrev={ this.handlePrev }
        />
      </Layout>
    )
  }
}
const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => bindActionCreators({
  slideChange: Actions.Presentation.slideChange,
}, dispatch)

export default FunctionUtils.compose(
  Router.withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Presentation)
