import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import cx from 'classnames'

import {
  Header, Grid, Button, I18n, Router,
} from 'components'

import styles from './styles.module.scss'


export default class PresentationHeader extends PureComponent {
  propTypes = {
    isFinish: PropTypes.bool.isRequired,
    className: PropTypes.string,
    finishLesson: PropTypes.func.isRequired,
    linkBack: PropTypes.shape({
      classId: PropTypes.string.isRequired,
    }).isRequired,
  }

  defaultProps = {
    className: '',
  }

  state = {
    redirect: false,
  }

  handleFinish = finishLesson => {
    finishLesson()
    this.setState({
      redirect: true,
    })
  }

  renderRedirect = linkBack => {
    if (this.state.redirect) {
      return <Router.Redirect to={ `/classes/${linkBack.classId}/subjects/${linkBack.subjectId}` } />
    }
    return null
  }

  renderFinish = (finishLesson, isFinish) => {
    if (isFinish) {
      return (
        <Button onClick={ () => this.handleFinish(finishLesson) }>
          <I18n.Translate value="presentation.finish" />
        </Button>
      )
    }
    return null
  }

  render() {
    const {
      className, linkBack, finishLesson, isFinish,
    } = this.props
    return (
      <Header className={ cx(styles.header, className) }>
        <Grid.Row type="flex" align="middle">
          <Grid.Col span={ 16 }>info</Grid.Col>
          <Grid.Col span={ 8 } className={ styles['finish-col'] }>
            {this.renderRedirect(linkBack)}
            {this.renderFinish(finishLesson, isFinish)}
          </Grid.Col>
        </Grid.Row>
      </Header>
    )
  }
}
