import { createAction } from 'redux-actions'

import { ReduxUtils } from 'utils'

const initStarted = createAction('APP/INIT_STARTED')
const initSuccessed = createAction('APP/INIT_SUCCESSED')
const initFailed = createAction('APP/INIT_FAILED')
const initFinished = createAction('APP/INIT_FINISHED')

const init = ReduxUtils.waitForAction(
  createAction('APP/INIT'),
  initSuccessed,
  initFailed,
)

const setLocale = createAction('APP/SET_LOCALE')


export {
  init,
  setLocale,

  initStarted,
  initSuccessed,
  initFailed,
  initFinished,
}
