export default {
  week: '%{week}: ',
  lesson: ' %{lesson}',
  weekMaterials: '%{week}. Материалы',
  contentHeader: 'Класс %{classNumber}%{classLiteral}. %{discipline}',
  lessonBtnStart: 'Начать урок',
  lessonBtnStartOver: 'Начать сначала',
  lessonBtnContinue: 'Продолжить с %{number} стр.',
  lessonInstructions: 'Методические указания',
  lessonMoreInstructions: 'Дополнительные материалы',
}
