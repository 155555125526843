import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'

import flowRight from 'lodash/flowRight'

const FunctionUtils = {
  debounce,
  throttle,
  invoke: (fn, ...args) => {
    setTimeout(() => fn(...args), 0)
  },
  compose: (...args) => flowRight(args),
  stub: () => () => {},
}

export default FunctionUtils
