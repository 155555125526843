import uuid from 'uuid/v4'

export default class RevealRPCService {
  constructor() {
    this.onRawMessage = this.onRawMessage.bind(this)

    this.requests = {}

    window.addEventListener('message', this.onRawMessage)
  }

  onRawMessage(event) {
    if (typeof event.data === 'string') {
      try {
        const response = JSON.parse(event.data)

        if (response && response.protocol === 'reveal-rpc') {
          const {
            id,
            result,
            error,
          } = response

          const request = this.requests[id]

          if (request) {
            if (error) {
              request.reject(error)
            } else {
              request.resolve(result)
            }
          }
        }
      } catch (e) {} // eslint-disable-line
    }
  }

  call(frame, method, ...args) {
    return new Promise((resolve, reject) => {
      const id = uuid()

      this.requests[id] = {
        resolve,
        reject,
      }

      const request = {
        protocol: 'reveal-rpc',
        id,
        method,
        arguments: args,
      }

      frame.postMessage(JSON.stringify(request), '*')
    })
  }
}
