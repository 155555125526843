export const parseMessage = event => {
  if (typeof event.data === 'string') {
    try {
      const data = JSON.parse(event.data)

      if (data.namespace === 'reveal') {
        return data
      }
    } catch (e) {} // eslint-disable-line
  }

  return null
}
