import { API as APIConfig } from 'configs'

import API from './API'

import Auth from './Auth'
import User from './User'
import Classes from './Classes'
import Subjects from './Subjects'
import Lessons from './Lessons'
import Presentation from './Presentation'
import Weeks from './Weeks'
import RevealRPC from './RevealRPC'

const APIService = new API(APIConfig.BASE_URL)

const AuthService = new Auth(APIService)
const UserService = new User(APIService)
const ClassesService = new Classes(APIService)
const SubjectsService = new Subjects(APIService)
const LessonsService = new Lessons(APIService)
const WeeksService = new Weeks(APIService)
const PresentationService = new Presentation(APIService)
const RevealRPCService = new RevealRPC()

export {
  APIService,

  AuthService,
  UserService,
  ClassesService,
  SubjectsService,
  LessonsService,
  WeeksService,
  PresentationService,
  RevealRPCService,
}
