import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { RevealRPCService } from 'services'
import { FunctionUtils, KeyUtils } from 'utils'

import { parseMessage } from './utils'

import styles from './styles.module.scss'

export default class PresentationView extends PureComponent {
  static propTypes = {
    url: PropTypes.string.isRequired,

    slide: PropTypes.number,
    fullscreen: PropTypes.bool,

    handleIsLastFragmentChange: PropTypes.func,
    handleIsFirstFragmentChange: PropTypes.func,
    onReady: PropTypes.func,
    onSlideChanged: PropTypes.func,
  }

  static defaultProps = {
    slide: 1,
    fullscreen: false,

    handleIsLastFragmentChange: FunctionUtils.stub(),
    handleIsFirstFragmentChange: FunctionUtils.stub(),
    onReady: FunctionUtils.stub(),
    onSlideChanged: FunctionUtils.stub(),
  }

  lock = false

  iframe = React.createRef()

  componentDidMount() {
    window.addEventListener('message', this.onRawMessage, false)
  }

  componentDidUpdate(prevProps) {
    const { slide, fullscreen } = this.props

    if (prevProps.slide !== slide) {
      this.slide(slide)
    }

    if (prevProps.fullscreen !== fullscreen) {
      if (fullscreen) {
        this.triggerKey('f')
      } else {
        this.triggerKey('esc')
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.onRawMessage)
  }

  getSlidesCount = (currentSlide = 1) => new Promise(resolve => {
    this.lock = true

    const onResponse = event => {
      const data = parseMessage(event)

      if (data && data.eventName === 'slidechanged') {
        window.removeEventListener('message', onResponse)

        const lastSlide = data.state.indexh + 1

        this.slide(currentSlide > lastSlide ? 1 : currentSlide)
        this.lock = false

        resolve(lastSlide)
      }
    }

    window.addEventListener('message', onResponse)

    this.toLastSlide()
  })

  handleFragmentChanges = () => {
    if (this.iframe.current) {
      RevealRPCService.call(this.iframe.current.contentWindow, 'availableFragments').then(isLast => {
        this.props.handleIsLastFragmentChange(!isLast.next)
        this.props.handleIsFirstFragmentChange(!isLast.prev)
      }).catch(e => {
        console.log('RevealRPCService error =', e)
      })
    }
  }

  onMessage = message => {
    switch (message.eventName) {
      case 'ready': {
        this.configure({
          controls: false,
          controlsTutorial: false,
          progress: false,
          keyboard: false,
          overview: false,
          touch: false,
          embedded: true,
          help: false,
        })

        this.getSlidesCount(this.props.slide).then(count => {
          this.handleReady(count)
        })
        if (this.iframe.current) {
          RevealRPCService.call(this.iframe.current.contentWindow, 'getTotalSlides').then(count => {
            console.log('RevealRPCService result =', count)
          }).catch(e => {
            console.log('RevealRPCService error =', e)
          })
        }

        break
      }

      case 'fragmentshown': { // Если false, то фрагмент был последним
        this.handleFragmentChanges()

        break
      }

      case 'fragmenthidden': { // Если false, то фрагмент был последним
        this.handleFragmentChanges()

        break
      }

      case 'slidechanged': {
        this.handleFragmentChanges()
        this.handleSlideChange(message.state.indexh + 1)

        break
      }
      default:
    }
  }

  onRawMessage = event => {
    if (this.lock) {
      return
    }

    const data = parseMessage(event)

    if (data) {
      this.onMessage(data)
    }
  }

  next = () => {
    this.invoke('right')
  }

  prev = () => {
    this.invoke('left')
  }

  toLastSlide = () => {
    this.configure({ loop: true })
    this.slide(0)
    this.configure({ loop: false })
  }

  handleReady = total => {
    this.props.onReady(total)
  }

  handleSlideChange = slide => {
    this.props.onSlideChanged(slide)
  }

  invoke = (method, ...args) => {
    const iframe = this.iframe.current

    if (iframe) {
      iframe.contentWindow.postMessage(JSON.stringify({
        method,
        args,
      }), '*')
    }
  }

  configure = (config = {}) => {
    this.invoke('configure', config)
  }

  triggerKey = key => {
    this.invoke('triggerKey', KeyUtils.toCharCode(key))
  }

  slide = slide => {
    this.invoke('slide', slide - 1, 0, -1)
  }

  render() {
    const {
      url,
    } = this.props

    return (
      <div className={ styles.presentation }>
        <iframe
          ref={ this.iframe }
          src={ `${url}?postMessageEvents=true` }
          title="presentation"
          width="100%"
          height="100%"
          frameBorder="0"
          scrolling="no"
          allowFullScreen
        />
      </div>
    )
  }
}
