import React, { PureComponent } from 'react'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Actions, Selectors } from 'ducks'
import PropTypes from 'prop-types'

import {
  Router, Scrollable, Loading, I18n,
} from 'components'
import { Badge, Icon } from 'antd'
import { FunctionUtils } from 'utils'

import LessonCollapse from './Collapse'
import SelectWeeks from './Select'
import styles from './styles.module.scss'


class ContentSubjects extends PureComponent {
  static propTypes = {
    getClasses: PropTypes.func.isRequired,
    getLessons: PropTypes.func.isRequired,
    getWeeks: PropTypes.func.isRequired,
    getSubjects: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        subjectId: PropTypes.string.isRequired,
        classId: PropTypes.string.isRequired,
      }),
    }).isRequired,
    classes: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
    })).isRequired,
    loading: PropTypes.bool.isRequired,
    loadingWeeks: PropTypes.bool.isRequired,
    weeks: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
    })).isRequired,
    lessons: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
    })).isRequired,
    resetProgress: PropTypes.func.isRequired,
    clearData: PropTypes.func.isRequired,
    discipline: PropTypes.string,
  }

  static defaultProps = {
    discipline: '',
  }

  state = {
    week: null,
  }

  componentDidMount() {
    const {
      subjectId,
      classId,
    } = this.props.match.params
    this.props.getSubjects(classId).then(subjects => {
      const currentSubject = subjects.find(subject => parseInt(subject.id, 10) === parseInt(subjectId, 10))

      this.props.getWeeks(subjectId).then(weeks => {
        const week = weeks.find(_week => _week.id === currentSubject.week.id)
        this.handleChangeWeek(week.id)
      })
    })
    this.props.getClasses()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.subjectId !== this.props.match.params.subjectId) {
      const {
        subjectId,
        classId,
      } = this.props.match.params
      this.props.getSubjects(classId).then(subjects => {
        const currentSubject = subjects.find(subject => parseInt(subject.id, 10) === parseInt(subjectId, 10))

        this.props.getWeeks(subjectId).then(weeks => {
          const week = weeks.find(_week => _week.id === currentSubject.week.id)
          this.handleChangeWeek(week.id)
        })
      })
      this.props.clearData()
    }
  }

  componentWillUnmount() {
    this.props.clearData()
  }

  handleGetHeader = () => {
    const _class = this.props.classes.find(Sclass => (
      parseInt(Sclass.id, 10) === parseInt(this.props.match.params.classId, 10)
    ))
    if (_class !== undefined) { return (_class) }
    return { number: '1', literal: 'a' }
  }

  handleChangeWeek = week => {
    if (typeof week === 'number') {
      this.setState({
        week,
      })
      this.props.getLessons(week)
    }
  }

  renderWeekDescription = (weeks, week) => {
    if (typeof week === 'number') {
      const currentWeek = weeks.find(_week => (_week.id === week))
      if (typeof currentWeek !== 'undefined') {
        return (
          <div className={ styles['content-links'] }>
            <Badge count={ <Icon type="link" className={ styles.link } /> }>
              <a rel="noopener noreferrer" target="_blank" href={ `${currentWeek.resource_url}` }>
                <I18n.Translate
                  value="workspace.weekMaterials"
                  week={ currentWeek.name }
                />
              </a>
            </Badge>
          </div>
        )
      }
    }
    return null
  }

  render() {
    const {
      loading, weeks, lessons, match, discipline, resetProgress, loadingWeeks,
    } = this.props

    const { week } = this.state

    if (loading || loadingWeeks) {
      return (<Loading />)
    }

    return (
      <div key={ match.params.subjectId } className={ styles['content-subjects'] }>
        <div className={ styles['content-header'] }>
          <h1>
            <I18n.Translate
              value="workspace.contentHeader"
              classNumber={ this.handleGetHeader().number }
              classLiteral={ this.handleGetHeader().literal.toUpperCase() }
              discipline={ discipline }
            />
          </h1>
          <SelectWeeks weeks={ weeks } week={ week } changeWeek={ this.handleChangeWeek } />
          {
            this.renderWeekDescription(weeks, week)
          }
        </div>
        <div className={ styles['content-lessons'] }>
          <Scrollable>
            <LessonCollapse
              reset={ resetProgress }
              loading={ loading }
              lessons={ lessons }
              match={ match.params }
              className={ styles.lessons }
            />
          </Scrollable>
        </div>
      </div>

    )
  }
}


const mapStateToProps = state => ({
  discipline: Selectors.Weeks.getDiscipline(state),
  weeks: Selectors.Weeks.getWeeks(state),
  subjects: Selectors.Subjects.getSubjects(state),
  classes: Selectors.Classes.getClasses(state),
  lessons: Selectors.Lessons.getLessons(state),
  loading: Selectors.Lessons.loading(state),
  loadingWeeks: Selectors.Weeks.loading(state),
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getWeeks: Actions.Weeks.fetch,
  getLessons: Actions.Lessons.fetch,
  getClasses: Actions.Classes.getClasses,
  getSubjects: Actions.Subjects.fetch,
  resetProgress: Actions.Presentation.resetProgress,
  clearData: Actions.Lessons.clearData,
}, dispatch)

export default FunctionUtils.compose(
  Router.withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(ContentSubjects)
