import { handleActions } from 'redux-actions'

import produce from 'immer'

import {
  fetchSuccessed,
} from './actions'

const initialState = {
  id: null,
  name: '',
  email: '',
  avatar: '',
}

const reducer = handleActions({
  [fetchSuccessed]: (state, action) => produce(state, next => {
    const {
      id,
      name,
      email,
      avatar,
    } = action.payload

    next.id = id
    next.name = name
    next.email = email
    next.avatar = avatar
  }),
}, initialState)

export default reducer
