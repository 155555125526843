import React from 'react'
import PropTypes from 'prop-types'

import cx from 'classnames'

import {
  Layout,
  Grid,
} from 'components'

import styles from './styles.module.scss'

const Footer = ({ className }) => (
  <Layout.Footer className={ cx(styles.footer, className) }>
    <Grid.Row type="flex" align="middle" justify="center">
      <Grid.Col>
        A-Class &copy; 2019
      </Grid.Col>
    </Grid.Row>
  </Layout.Footer>
)

Footer.propTypes = {
  className: PropTypes.string,
}

Footer.defaultProps = {
  className: '',
}

export default Footer
