import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import * as locales from 'locales'

import { LocaleProvider } from 'antd'

const AntdLocaleProvider = ({ locale, ...props }) => <LocaleProvider { ...props } locale={ locales[locale].antd } />

AntdLocaleProvider.propTypes = {
  locale: PropTypes.string.isRequired,
}

const mapStateToProps = state => ({
  locale: state.app.locale,
})

export default connect(mapStateToProps)(AntdLocaleProvider)
