import React, { PureComponent } from 'react'

import PropTypes from 'prop-types'

import { Router, Loading, I18n } from 'components'
import {
  Collapse, Avatar, Icon, Badge,
} from 'antd'


import styles from './styles.module.scss'


export default class LessonCollapse extends PureComponent {
  static propTypes = {
    lessons: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
    })).isRequired,
    loading: PropTypes.bool.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        subjectId: PropTypes.string.isRequired,

      }),
    }).isRequired,
    reset: PropTypes.func.isRequired,
  }

  handleResetLesson = id => {
    this.props.reset(id)
  }

  renderHeader = params => {
    let color = 'progress'
    if (params.current_slide > 1 && !(params.is_completed)) {
      color = 'progress-warning'
    }
    if (params.is_completed) {
      color = 'progress-success'
    }
    return (
      <div className={ styles['lesson-header'] }>
        <Avatar
          shape="oval"
          size="large"
          className={ styles[color] }
        >
          {`${params.current_slide}/${params.total_slides}`}
        </Avatar>
        <h3 className={ styles.header }>{params.name}</h3>
        <small className={ styles.time } />
      </div>
    )
  }


  renderPresentationLinks = params => {
    if (params.is_completed) {
      return (
        <div className={ styles['content-play'] }>
          <button
            type="button"
            className={ styles['play-background'] }
            onClick={ () => this.handleResetLesson(params.lessonId) }
          >
            <Router.Link
              to={ `/classes/${params.classId}/subjects/${params.subjectId}/lessons/${params.lessonId}/presentation` }
              className={ styles['play-link'] }
            >
              <Avatar shape="square" size="large" icon="reload" className={ styles['play-btn'] } />
              <h3 className={ styles.text }>
                <I18n.Translate
                  value="workspace.lessonBtnStartOver"
                />
              </h3>
            </Router.Link>
          </button>
        </div>
      )
    }
    if (params.current_slide === 1) {
      return (
        <div className={ styles['content-play'] }>
          <button type="button" className={ styles['play-background'] }>
            <Router.Link
              to={ `/classes/${params.classId}/subjects/${params.subjectId}/lessons/${params.lessonId}/presentation` }
              className={ styles['play-link'] }
            >
              <Avatar shape="square" size="large" icon="play-circle" className={ styles['play-btn'] } />
              <h3 className={ styles.text }>
                <I18n.Translate
                  value="workspace.lessonBtnStart"
                />
              </h3>
            </Router.Link>
          </button>
        </div>
      )
    }
    if (params.current_slide > 1 && !(params.is_completed)) {
      return (
        <div className={ styles['content-play'] }>
          <button type="button" className={ styles['play-background'] }>
            <Router.Link // eslint-disable-next-line max-len
              to={ `/classes/${params.classId}/subjects/${params.subjectId}/lessons/${params.lessonId}/presentation?from=${params.current_slide}` }
              className={ styles['play-link'] }
            >
              <Avatar shape="square" size="large" icon="play-circle" className={ styles['play-btn'] } />
              <h3 className={ styles.text }>
                <I18n.Translate
                  value="workspace.lessonBtnContinue"
                  number={ params.current_slide }
                />
              </h3>
            </Router.Link>
          </button>
          <button
            type="button"
            className={ styles['reset-background'] }
            onClick={ () => this.handleResetLesson(params.lessonId) }
          >
            <Router.Link
              to={ `/classes/${params.classId}/subjects/${params.subjectId}/lessons/${params.lessonId}/presentation` }
              className={ styles['reset-link'] }
            >
              <Avatar shape="circle" size="medium" icon="reload" className={ styles['reset-btn'] } />
              <h4 className={ styles.text }>
                <I18n.Translate
                  value="workspace.lessonBtnStartOver"
                />
              </h4>
            </Router.Link>
          </button>
        </div>
      )
    }

    return null
  }

  renderContent = params => (

    <div className={ styles['lesson-content'] }>
      <div className={ styles['content-links'] }>
        <div className={ styles['links-block'] }>
          <Badge count={ <Icon type="link" className={ styles.link } /> }>
            <a rel="noopener noreferrer" target="_blank" href={ `${params.resource_url}` }>
              <I18n.Translate
                value="workspace.lessonInstructions"
              />
            </a>
          </Badge>
        </div>
        <div>
          <Badge count={ <Icon type="link" className={ styles.link } /> }>
            <a rel="noopener noreferrer" target="_blank" href={ `${params.resource_url}` }>
              <I18n.Translate
                value="workspace.lessonMoreInstructions"
              />
            </a>
          </Badge>
        </div>
      </div>
      {this.renderPresentationLinks(params)}
    </div>
  )

  renderLessons = (lessons, match) => lessons.map(lesson => this.renderLesson(lesson, match))

  renderLesson = (lesson, match) => {
    const params = {
      classId: match.classId,
      subjectId: match.subjectId,
      lessonId: lesson.id,
      is_completed: lesson.presentation.is_completed,
      total_slides: lesson.presentation.total_slides,
      current_slide: lesson.presentation.current_slide,
      name: lesson.name,
      resource_url: lesson.resource_url,
    }

    return (
      <Collapse.Panel
        key={ lesson.id }
        header={ this.renderHeader(params) }
        showArrow={ false }
      >
        {this.renderContent(params)}
      </Collapse.Panel>
    )
  }

  render() {
    const {
      loading, lessons, match,
    } = this.props
    if (loading) {
      return (<Loading />)
    }
    return (
      <Collapse accordion className={ styles.lessons }>
        { this.renderLessons(lessons, match) }
      </Collapse>
    )
  }
}
