import get from 'lodash/get'

import pickBy from 'lodash/pickBy'
import identity from 'lodash/identity'

const ObjectUtils = {
  get,
  omitEmpty: obj => pickBy(obj, identity),
}

export default ObjectUtils
