import { eventChannel } from 'redux-saga'

import {
  fork,
  call,
  put,
  putResolve,
  take,
  takeLatest,
  cancelled,
} from 'redux-saga/effects'

import {
  AuthService,
  APIService,
} from 'services'

import { Actions } from 'ducks'

import {
  signIn,
  signOut,

  signInRequestStarted,
  signInRequestSuccessed,
  signInRequestFailed,
  signInRequestFinished,

  signOutRequestStarted,
  signOutRequestSuccessed,
  signOutRequestFailed,
  signOutRequestFinished,
} from './actions'

function createAPIServiceAuthErrorEventChannel() {
  return eventChannel(emitter => {
    const unsubscribe = APIService.addErrorHandler(response => {
      emitter(response.code === 401)

      return Promise.reject()
    })

    return () => {
      if (typeof unsubscribe === 'function') {
        unsubscribe()
      }
    }
  })
}

function* apiServiceAuthErrorTask() {
  const apiServiceAuthErrorEventChannel = yield call(createAPIServiceAuthErrorEventChannel)

  try {
    while (true) {
      const is401 = yield take(apiServiceAuthErrorEventChannel)

      if (is401) {
        yield putResolve(signOutRequestSuccessed())

        window.location.href = '/'
      }
    }
  } finally {
    if (yield cancelled()) {
      apiServiceAuthErrorEventChannel.close()
    }
  }
}

function* signInTask(action) {
  const {
    payload: {
      login,
      password,
    },
  } = action

  yield put(signInRequestStarted())

  try {
    const accessToken = yield call([AuthService, 'signIn'], login, password)

    yield call([APIService, 'setAccessToken'], accessToken)

    yield putResolve(signInRequestSuccessed(accessToken))
    yield putResolve(Actions.App.init())
  } catch (err) {
    yield put(signInRequestFailed(err))
  }

  yield put(signInRequestFinished())
}

function* signOutTask() {
  yield put(signOutRequestStarted())

  try {
    APIService.setAccessToken(null)

    yield put(signOutRequestSuccessed())
  } catch (err) {
    yield put(signOutRequestFailed(err))
  }

  yield put(signOutRequestFinished())
}

export default function* () {
  yield fork(apiServiceAuthErrorTask)

  yield takeLatest(signIn, signInTask)
  yield takeLatest(signOut, signOutTask)
}
