import { handleActions } from 'redux-actions'
import { persistReducer } from 'redux-persist'

import storage from 'localforage'

import produce from 'immer'

import {
  signInRequestSuccessed,
  signOutRequestSuccessed,
} from './actions'

const initialState = {
  isAuthorized: false,
  accessToken: null,
}

const reducer = handleActions({
  [signInRequestSuccessed]: (state, action) => produce(state, next => {
    next.isAuthorized = true
    next.accessToken = action.payload
  }),
  [signOutRequestSuccessed]: state => produce(state, next => {
    next.isAuthorized = false
    next.accessToken = null
  }),
}, initialState)

export default persistReducer({
  key: 'auth',
  storage,
}, reducer)
