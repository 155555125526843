import React from 'react'
import ReactDOM from 'react-dom'

const ReactUtils = {
  render: (Component, mountPoint) => {
    ReactDOM.render(Component, mountPoint)
  },
  renderChildren: (children, wrapper = 'div') => {
    if (React.isValidElement(children)) {
      return children
    }

    if (!children) {
      return null
    }

    return React.createElement(wrapper, {}, ...React.Children.toArray(children))
  },
  getDisplayName: Component => Component.displayName || Component.name || 'Component',
  stopPropagation: e => {
    e.stopPropagation()
  },
}

export default ReactUtils
