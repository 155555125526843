import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { I18n } from 'components'
import {
  Avatar, Modal, Button, InputNumber, Input,
} from 'antd'
import styles from './styles.module.scss'


export default class ModalAdd extends PureComponent {
  static propTypes = {
    addClass: PropTypes.func.isRequired,


  }


  state = {
    visible: false,
    number: 1,
    literal: '',

  }

  modalShow = () => {
    this.setState({
      visible: true,
    })
  }

  handleLiteralChange = e => {
    this.setState({ literal: e.target.value })
  }

  handleNumberChange = value => {
    this.setState({ number: value })
  }

  handleOk = e => {
    this.props.addClass(this.state.number, this.state.literal)
    this.setState({
      visible: false,
    })
  }

  handleCancel = e => {
    this.setState({
      visible: false,
    })
  }


  render() {
    return (
      <div className={ styles.modal }>
        <Avatar
          style={ { verticalAlign: 'middle' } }
          className={ styles['modal-link'] }
          size="large"
          onClick={ this.modalShow }
        >
        +
        </Avatar>
        <Modal
          title={ (
            <I18n.Translate
              value="sideHeader.classesAddHeader"
            />
          ) }
          visible={ this.state.visible }
          onCancel={ this.handleCancel }
          footer={ [
            <Button key="back" onClick={ this.handleCancel }>
              <I18n.Translate
                value="sideHeader.classesBtnBack"
              />
            </Button>,
            <Button key="submit" type="primary" onClick={ this.handleOk }>
              <I18n.Translate
                value="sideHeader.classesBtnAdd"
              />
            </Button>,
          ] }
        >
          <InputNumber min={ 1 } max={ 10 } defaultValue={ 1 } onChange={ this.handleNumberChange } />
          <Input
            type="text"
            onChange={ this.handleLiteralChange }
            placeholder="Литерал класса"
            style={ { width: '60%' } }
          />
        </Modal>
      </div>


    )
  }
}
