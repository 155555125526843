

export default class LessonsService {
  constructor(apiService) {
    this._apiService = apiService
  }

  _mapLesson = lesson => ({
    id: lesson.id,
    name: lesson.name,
    presentation: lesson.presentation,
    resource_url: lesson.resource_url,
  })

  _mapLessons = lessons => lessons.map(lesson => this._mapLesson(lesson))


  getLessons(weekId) {
    return this._apiService.get(`/lessons/${weekId}`).then(lessons => this._mapLessons(lessons.data.data))

    // return new Promise(resolve => {
    //   const subjects = this._mapSubjects(subjectsMock)

    //   setTimeout(() => {
    //     resolve(subjects)
    //   }, Math.round(Math.random() * 2 + 1))
    // })
  }
}
