import qs from 'qs'

const decoder = (str, plainDecoder, charset) => {
  const decoded = plainDecoder(str, plainDecoder, charset)

  if (decoded.length === 0) {
    return undefined
  }

  if (/^\d+$/i.test(decoded)) {
    return Number.parseInt(decoded, 10)
  }

  return decoded
}

export const toQueryString = obj => qs.stringify(obj || {}, {
  addQueryPrefix: true,
})

export const fromQueryString = str => qs.parse(str || '', {
  ignoreQueryPrefix: true,
  decoder,
})
