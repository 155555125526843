import { handleActions } from 'redux-actions'

import produce from 'immer'

import {
  fetchStarted,
  fetchGetSuccessed,
  fetchAddSuccessed,
  fetchDelSuccessed,
  fetchEditSuccessed,
  fetchFailed,
  fetchFinished,
} from './actions'

const initialState = {
  loading: false,
  data: [],
  error: false,
}

const reducer = handleActions({
  [fetchStarted]: state => produce(state, next => {
    next.loading = true
  }),
  [fetchGetSuccessed]: (state, action) => produce(state, next => {
    next.data = action.payload
  }),
  [fetchAddSuccessed]: (state, action) => produce(state, next => {
    next.data = [...state.data, action.payload]
  }),
  [fetchDelSuccessed]: (state, action) => produce(state, next => {
    next.data = state.data.filter(_class => _class.id !== action.payload)
  }),
  [fetchEditSuccessed]: (state, action) => produce(state, next => {
    const index = state.data.findIndex(_class => _class.id === action.payload.id)

    if (index >= 0) {
      next.data[index] = action.payload
    }
  }),
  [fetchFailed]: (state, action) => produce(state, next => {
    next.error = action.payload
  }),
  [fetchFinished]: state => produce(state, next => {
    next.loading = false
  }),
}, initialState)

export default reducer
