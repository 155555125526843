import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import {
  Avatar,
  Router,
  Scrollable,
  Loading,
  I18n,
} from 'components'

import { Actions, Selectors } from 'ducks'

import { FunctionUtils } from 'utils'

import styles from './styles.module.scss'

class SiderSubjects extends PureComponent {
  static propTypes = {
    match: PropTypes.shape({
      url: PropTypes.string.isRequired,
      params: PropTypes.shape({
        classId: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,

    loading: PropTypes.bool.isRequired,
    subjects: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string.isRequired,
    })).isRequired,

    getSubjects: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const {
      match: {
        params: {
          classId,
        },
      },
    } = this.props

    this.props.getSubjects(classId)
  }

  componentDidUpdate(prevProps) {
    if (prevProps) {
      const {
        match: {
          params: {
            classId: prevClassId,
          },
        },
      } = prevProps

      const {
        match: {
          params: {
            classId,
          },
        },
      } = this.props

      if (prevClassId !== classId) {
        this.props.getSubjects(classId)
      }
    }
  }

  renderDescription = subject => {
    const items = []

    if (subject.week || subject.numberLesson) {
      if (subject.week) {
        items.push(
          <I18n.Translate
            key={ `${subject.id}/week` }
            className={ styles.item }
            value="workspace.week"
            week={ subject.week.name }
          />,
        )
      }

      if (subject.numberLesson) {
        items.push(
          <I18n.Translate
            key={ `${subject.id}/lesson` }
            className={ styles.item }
            value="workspace.lesson"
            lesson={ subject.numberLesson.name }
          />,
        )
      }

      return (
        <div className={ styles.description }>
          { items }
        </div>
      )
    }

    return null
  }

  renderSubject = (subject, url) => (
    <Router.NavLink
      key={ subject.id }
      to={ `${url}/subjects/${subject.id}` }
      className={ styles.subject }
      activeClassName={ styles.active }
    >
      <Avatar
        className={ styles.logo }
        shape="square"
        size="large"
        src={ `/files/upload/images/disciplines/${subject.id}/${subject.image}` }
      />
      <div className={ styles.content }>
        <div className={ styles.title }>
          {subject.title}
        </div>
        { this.renderDescription(subject) }
      </div>
    </Router.NavLink>
  )

  renderSubjects = (subjects, url) => subjects.map(subject => this.renderSubject(subject, url))

  render() {
    const {
      match: {
        url,
      },

      loading,
      subjects,
    } = this.props

    if (loading) {
      return <Loading />
    }

    return (
      <div className={ styles['sider-subjects'] }>
        <Scrollable>
          <div className={ styles.subjects }>
            { this.renderSubjects(subjects, url) }
          </div>
        </Scrollable>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  subjects: Selectors.Subjects.getSubjects(state),
  loading: Selectors.Subjects.loading(state),
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getSubjects: Actions.Subjects.fetch,
}, dispatch)

export default FunctionUtils.compose(
  Router.withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(SiderSubjects)
