import {
  takeLatest, put, putResolve, call, select,
} from 'redux-saga/effects'

import { I18n } from 'components'

import { APIService } from 'services'

import { Actions, Selectors } from 'ducks'

import {
  init,
  setLocale,

  initStarted,
  initSuccessed,
  initFailed,
  initFinished,
} from './actions'


function* initTask() {
  yield put(initStarted())

  try {
    const isAuthorized = yield select(Selectors.Auth.isAuthorized)
    const accessToken = yield select(Selectors.Auth.getAccessToken)

    if (isAuthorized) {
      yield call([APIService, 'setAccessToken'], accessToken)

      yield putResolve(Actions.User.fetch())
    }

    yield put(initSuccessed())
  } catch (err) {
    yield put(initFailed(err))
  }

  yield put(initFinished())
}

function* setLocaleTask({ payload }) {
  yield call([I18n.Helpers, 'setLocale'], payload)
}

export default function* () {
  yield takeLatest(init, initTask)

  yield takeLatest(setLocale, setLocaleTask)
}
