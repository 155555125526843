import common from './common'
import antd from './antd'
import workspace from './workspace'
import presentation from './presentation'
import auth from './auth'
import sideHeader from './sideHeader'

export default {
  common,
  antd,
  workspace,
  sideHeader,
  presentation,
  auth,
}
