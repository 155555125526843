import { createAction } from 'redux-actions'

import { ReduxUtils } from 'utils'

const signInRequestStarted = createAction('AUTH/SIGN_IN_REQUEST_STARTED')
const signInRequestSuccessed = createAction('AUTH/SIGN_IN_REQUEST_SUCCESSED')
const signInRequestFailed = createAction('AUTH/SIGN_IN_REQUEST_FAILED')
const signInRequestFinished = createAction('AUTH/SIGN_IN_REQUEST_FINISHED')

const signIn = ReduxUtils.waitForAction(
  createAction('AUTH/SIGN_IN', (login, password) => ({
    login,
    password,
  })),
  signInRequestSuccessed,
  signInRequestFailed,
)

const signOutRequestStarted = createAction('AUTH/SIGN_OUT_REQUEST_STARTED')
const signOutRequestSuccessed = createAction('AUTH/SIGN_OUT_REQUEST_SUCCESSED')
const signOutRequestFailed = createAction('AUTH/SIGN_OUT_REQUEST_FAILED')
const signOutRequestFinished = createAction('AUTH/SIGN_OUT_REQUEST_FINISHED')

const signOut = ReduxUtils.waitForAction(
  createAction('AUTH/SIGN_OUT'),
  signOutRequestSuccessed,
  signOutRequestFailed,
)

export {
  signIn,
  signOut,

  signInRequestStarted,
  signInRequestSuccessed,
  signInRequestFailed,
  signInRequestFinished,

  signOutRequestStarted,
  signOutRequestSuccessed,
  signOutRequestFailed,
  signOutRequestFinished,
}
