import React from 'react'

import cx from 'classnames'

import { Form as FormAntd } from 'antd'

import { Input, Icon } from 'components'

import styles from './styles.module.scss'

const iconRenderer = icon => {
  if (typeof icon === 'string' && icon.length > 0) {
    return (
      <Icon
        type={ icon }
        style={ { color: 'rgba(0,0,0,.25)' } }
      />
    )
  }

  return null
}

const FormInput = ({
  className,

  input,
  meta,
  hasFeedback,
  icon,
  ...props
}) => {
  const hasError = meta.touched && meta.invalid

  return (
    <FormAntd.Item
      className={ cx(styles['form-input'], className) }
      validateStatus={ hasError ? 'error' : 'success' }
      hasFeedback={ hasFeedback && hasError }
      help={ hasError && meta.error }
    >
      <Input
        prefix={ iconRenderer(icon) }
        { ...input }
        { ...props }
      />
    </FormAntd.Item>
  )
}

export default FormInput
