import React from 'react'
import PropTypes from 'prop-types'

import cx from 'classnames'

import {
  Avatar,
  Header,
  Grid,
  Router,
  Icon,
} from 'components'

import styles from './styles.module.scss'

const WorkspaceHeader = ({ className, user }) => (
  <Header className={ cx(styles['workspace-header'], className) }>
    <Grid.Row type="flex" align="middle">
      <Grid.Col span={ 24 } className={ styles['right-col'] }>
        <Router.Link to="/profile" className={ styles['profile-link'] }>
          <Avatar
            className={ styles.avatar }
            src={ `/files/upload/images/profiles/${user.id}/${user.avatar}` }
          />
          { user.name }
        </Router.Link>
        <Router.Link to="/sign-out">
          <Icon type="logout" className={ styles['exit-icon'] } />
        </Router.Link>
      </Grid.Col>
    </Grid.Row>
  </Header>
)

WorkspaceHeader.propTypes = {
  className: PropTypes.string,

  user: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    avatar: PropTypes.string,
  }).isRequired,
}

WorkspaceHeader.defaultProps = {
  className: '',
}

export default WorkspaceHeader
