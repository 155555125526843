import React from 'react'
import PropTypes from 'prop-types'

import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'

import { Root } from 'containers'
import { AntdLocaleProvider } from 'components'

import { history } from 'store'

const App = ({ store }) => (
  <Provider store={ store }>
    <AntdLocaleProvider>
      <ConnectedRouter history={ history }>
        <Root />
      </ConnectedRouter>
    </AntdLocaleProvider>
  </Provider>
)

App.propTypes = {
  store: PropTypes.shape({}).isRequired,
}

export default App
