
export default class PresentationsService {
  constructor(apiService) {
    this._apiService = apiService
  }


  slideChange(action) {
    const {
      slide,
      lessonId,
      completed,
    } = action
    return this._apiService.put(`/presentation/${lessonId}`, {
      slide_number: slide,
      completed,
    }).then(response => response.progress).catch(e => {
      throw e
    })
  }

  resetProgress(action) {
    const {
      lessonId,
    } = action
    return this._apiService.post(`/presentation/${lessonId}/reset`).then(response => response).catch(e => {
      throw e
    })
  }
}
